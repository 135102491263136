import {
  ByID,
  DbRef,
  EncryptedUser,
  EncryptedWaypoint,
  Itinerary,
  MailInAction,
  Place,
  Procedure,
  ServiceRegion,
  Waypoint,
  WaypointAction,
  WaypointActionType,
  WaypointType,
  WithFriendlyStatus,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  Getters,
  ItinerariesModule,
  ItinerariesState,
  SupplyWithPatient,
  WithSkipUserFetches,
  firebaseBind,
  firebaseUnbind,
  generateHash,
  initItinerariesModule,
  unwrapMaybeEncryptedData,
} from '@caresend/ui-components';
import {
  arrayToObj,
  deduplicateArray,
  flattenArray,
  isMailInAction,
  nullishFilter,
} from '@caresend/utils';
import cloneDeep from 'lodash.clonedeep';

import { getUserIDsFromPromises } from '@/store/helpers';
import type { CustomActionContext, RootState, UserIDsObj } from '@/store/model';
import { deleteDraftItinerary } from '@/store/modules/itineraries/helpers';
import { useItineraryStore } from '@/store/modules/itinerary';
import { ExtendedProceduresGetters } from '@/store/modules/procedures';

type S = ItinerariesState & {
  /** By waypoint ID */
  serviceRegionCache: ByID<ServiceRegion>;
};

export type ExtraItinerariesActionContext = CustomActionContext<'itineraries', S>

export type BindWaypointActionParams = WithSkipUserFetches<{
  waypointActionID: string;
  bindItinerary?: boolean;
  bindProcedures?: boolean;
  bindWaypoint?: boolean;
}>

export const extraItinerariesMutations = {
  'itineraries/SET_SERVICE_REGION_CACHE_BY_WAYPOINT': (state: S,
    { waypoint, serviceRegions }: { waypoint: Waypoint; serviceRegions: ServiceRegion | undefined }) => {
    if (!serviceRegions) return;
    const latLng = { lat: waypoint.location.lat, lng: waypoint.location.lng };
    const hash = generateHash(latLng);
    state.serviceRegionCache = {
      ...state.serviceRegionCache,
      [hash]: serviceRegions,
    };
  },
};

export type ExtraItinerariesActions = {
  'itineraries/bindWaypoint': (
    context: ExtraItinerariesActionContext,
    payload: {
      waypointID: string;
      bindWaypointActions?: boolean;
    },
  ) => Promise<void>;

  'itineraries/bindWaypointAction': (
    context: ExtraItinerariesActionContext,
    params: BindWaypointActionParams,
  ) => Promise<UserIDsObj>;

  'itineraries/deleteDraftItinerary': (
    context: ExtraItinerariesActionContext,
    itinerary: Itinerary,
  ) => Promise<void>;

  'itineraries/unbindWaypoint': (
    context: ExtraItinerariesActionContext,
    waypointID: string,
  ) => Promise<void>;

  'itineraries/unbindWaypointAction': (
    context: ExtraItinerariesActionContext,
    waypointActionID: string,
  ) => Promise<void>;
}

const extraItinerariesActions: ExtraItinerariesActions = {
  'itineraries/bindWaypoint': async (
    { commit, dispatch },
    { waypointID, bindWaypointActions },
  ) => {
    const waypointPath = `${DbRef.WAYPOINTS}/${waypointID}`;
    await firebaseBind<EncryptedWaypoint>(waypointPath, async (encryptedWaypoint) => {
      if (!encryptedWaypoint) return firebaseUnbind(waypointPath);
      const { contact, waypointActions } = encryptedWaypoint;
      const waypoint: Waypoint = {
        ...encryptedWaypoint,
        contact: undefined,
      };

      if (bindWaypointActions && waypointActions) {
        const waypointActionPromises = waypointActions.map(({ id: waypointActionID }) =>
          dispatch('itineraries/bindWaypointAction', { waypointActionID, bindProcedures: true }),
        );
        await Promise.all(waypointActionPromises);
      }

      commit('itineraries/SET_WAYPOINT', cloneDeep(waypoint));

      if (waypoint.placeID) {
        await dispatch('places/fetchPlaces', {
          placeIDs: [waypoint.placeID],
          includePlaceGroups: true,
        });
      }

      if (!contact) return;

      const decryptedContact = await unwrapMaybeEncryptedData(contact);
      waypoint.contact = decryptedContact;
      commit('itineraries/SET_WAYPOINT', waypoint);

      commit('places/RESET_PLACES');
    });
  },

  'itineraries/bindWaypointAction': async (
    { commit, dispatch },
    { waypointActionID, bindItinerary, bindProcedures, bindWaypoint, skipUserFetches },
  ) => {
    const userIDs: string[] = [];

    const waypointActionPath = `${DbRef.WAYPOINT_ACTIONS}/${waypointActionID}`;
    await firebaseBind<WaypointAction>(waypointActionPath, async (waypointAction) => {
      if (!waypointAction) return firebaseUnbind(waypointActionPath);

      commit('itineraries/SET_WAYPOINT_ACTION', waypointAction);

      const promises: Promise<UserIDsObj | void>[] = [];

      const { itineraryID, procedures, waypointID } = waypointAction;

      if (bindProcedures && procedures) {
        const procedurePromises = Object.keys(procedures).map(
          (procedureID) => dispatch('procedures/bindProcedure', { procedureID, skipUserFetches }),
        );
        promises.push(...procedurePromises);
      }
      if (bindItinerary && itineraryID) {
        promises.push(dispatch('itineraries/bindItinerary', itineraryID));
      }
      if (bindWaypoint && waypointID) {
        promises.push(dispatch('itineraries/bindWaypoint', { waypointID }));
      }

      promises.push(
        dispatch('supplies/fetchShipments', { shipmentIDs: Object.keys(waypointAction.shipments ?? {}) }),
      );

      const resolvedPromises = await Promise.all(promises);
      userIDs.push(...getUserIDsFromPromises(resolvedPromises));
    });

    return { userIDs };
  },

  'itineraries/unbindWaypoint': async (_context, waypointID) => {
    const waypointPath = `${DbRef.WAYPOINTS}/${waypointID}`;
    return firebaseUnbind(waypointPath);
  },

  'itineraries/unbindWaypointAction': async (_context, waypointActionID) => {
    const waypointPath = `${DbRef.WAYPOINT_ACTIONS}/${waypointActionID}`;
    return firebaseUnbind(waypointPath);
  },

  'itineraries/deleteDraftItinerary': async (_context, itinerary: Itinerary) => {
    await deleteDraftItinerary(itinerary, _context.rootState);
  },
};

const extraItinerariesGetters = {
  'itineraries/getAllSuppliesOnItinerary': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
    rootGetters: Getters<ExtendedProceduresGetters>,
  ) => (itineraryID: string): SupplyWithPatient[] => {
    const procedures = extraItinerariesGetters[
      'itineraries/getProceduresOnItinerary'
    ](state, getters, rootState)(itineraryID);

    const supplies = flattenArray(
      procedures.map((procedure) => {
        const supplyInstructions = Object.values(
          procedure.supplyInstructionConfig?.supplyInstructions ?? {},
        );
        const patient = rootGetters[
          'procedures/getPatientOnProcedure'
        ](procedure.id);

        if (!patient) return [];

        const suppliesForProcedure: SupplyWithPatient[]
          = supplyInstructions.map((supplyInstruction) => {
            const { supplyID } = supplyInstruction;

            const supply = rootState.variables.variables?.supplies[supplyID];
            if (!supply) return null;

            const supplyWithPatient = { ...supply, patient };
            return supplyWithPatient;
          }).filter(nullishFilter);

        return suppliesForProcedure;
      }),
    );

    return supplies;
  },

  /** Supports both saved and draft itinerary */
  'itineraries/getMailInActionsOnItinerary': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (itineraryID: string): MailInAction[] => {
    const waypointActions = extraItinerariesGetters[
      'itineraries/getWaypointActionsOnItinerary'
    ](state, getters, rootState)(itineraryID);

    return waypointActions.filter(isMailInAction);
  },

  /** Supports both saved and draft itinerary */
  'itineraries/getPatientWaypointActionsOnItinerary': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (itineraryID: string): WaypointAction[] => {
    const waypointActions = extraItinerariesGetters[
      'itineraries/getWaypointActionsOnItinerary'
    ](state, getters, rootState)(itineraryID);

    return waypointActions.filter((waypointAction) =>
      waypointAction.type === WaypointActionType.PATIENT_ACTION,
    );
  },

  /** Supports Draft Itineraries */
  'itineraries/getPatientWaypointsOnItinerary': (
    state: S,
    _getters: AnyGetters,
    _rootState: RootState,
  ) => (itineraryID: string): Waypoint[] => {
    const waypoints = extraItinerariesGetters[
      'itineraries/getWaypointsOnItinerary'
    ](state, _getters)(itineraryID);

    return waypoints.filter((waypoint) => waypoint.type === WaypointType.PATIENT);
  },

  'itineraries/getPlacesOnItinerary': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (itineraryID: string): Place[] => {
    const placeIDs = extraItinerariesGetters[
      'itineraries/getPlaceIDsOnItinerary'
    ](state, _getters, rootState)(itineraryID);

    const places = placeIDs
      .map((id) => rootState.places.places?.[id])
      .filter(nullishFilter);

    return places;
  },

  'itineraries/getPlaceIDsOnItinerary': (
    state: S,
    _getters: AnyGetters,
    _rootState: RootState,
  ) => (itineraryID: string): string[] => {
    const waypoints = extraItinerariesGetters[
      'itineraries/getWaypointsOnItinerary'
    ](state, _getters)(itineraryID);

    const placeIDs = waypoints.map((waypoint) => waypoint.placeID)
      .filter(nullishFilter);

    return placeIDs;
  },

  /** Supports both saved and draft itinerary */
  'itineraries/getProceduresOnItinerary': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (itineraryID: string): WithFriendlyStatus<Procedure>[] => {
    const waypointActions = extraItinerariesGetters[
      'itineraries/getWaypointActionsOnItinerary'
    ](state, getters, rootState)(itineraryID);

    const procedureIDs = deduplicateArray(flattenArray(
      waypointActions.map((waypointAction) =>
        Object.keys(waypointAction.procedures ?? []),
      ),
    ));
    const procedures = procedureIDs
      .map((id) => rootState.procedures.procedures?.[id])
      .filter(nullishFilter);

    return procedures;
  },

  'itineraries/getServiceRegionCacheForWaypoint': (state: S) =>
    (waypoint: Waypoint): ServiceRegion | undefined => {
      if (!waypoint.location) return undefined;
      const latLng = { lat: waypoint.location.lat, lng: waypoint.location.lng };
      const hash = generateHash(latLng);
      return state.serviceRegionCache?.[hash];
    },

  'itineraries/getUsersOnItinerary': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (itineraryID: string): EncryptedUser[] => {
    const itinerary = state.itineraries?.[itineraryID];

    const assignedNurseID = itinerary?.nurseID;
    const offeredNurseIDs
        = Object.values(itinerary?.nursesOffered ?? {})
          .map((offer) => offer.nurseID);
    const nurseIDs = deduplicateArray([assignedNurseID, ...offeredNurseIDs]);

    const procedures = extraItinerariesGetters[
      'itineraries/getProceduresOnItinerary'
    ](state, getters, rootState)(itineraryID);

    const bookingIDs = deduplicateArray(
      procedures.map((procedure) => procedure.bookingID),
    );
    const bookings = bookingIDs.map((id) => rootState.bookings.bookings[id])
      .filter(nullishFilter);

    const orderIDs = deduplicateArray(
      procedures.map((procedure) => procedure.orderID),
    );
    const orders = orderIDs.map((id) => rootState.orders.orders?.[id])
      .filter(nullishFilter);

    const prescriberIDs = deduplicateArray(
      orders.map((order) => order.prescriberID),
    ).filter(nullishFilter);

    const patientIDs = deduplicateArray(
      bookings.map((booking) => booking.patientID),
    ).filter(nullishFilter);

    const userIDs = [
      ...nurseIDs,
      ...prescriberIDs,
      ...patientIDs,
    ].filter(nullishFilter);
    const users = userIDs.map((id) => rootState.users.users[id])
      .filter(nullishFilter);
    return users;
  },

  'itineraries/getWaypointFromWaypointAction': (state: S) =>
    (waypointAction: WaypointAction): Waypoint | null => {
      const { waypointID } = waypointAction;
      if (!waypointID) return null;
      return state.waypoints?.[waypointID] ?? null;
    },

  'itineraries/getWaypointsOnItinerary': (state: S, _getters: AnyGetters) =>
    (itineraryID: string): Waypoint[] => {
      const itineraryStore = useItineraryStore();
      const itinerary = itineraryID === itineraryStore.itinerary.value?.id
        ? itineraryStore.itinerary.value : state.itineraries?.[itineraryID];
      if (!itinerary) return [];

      const waypoints = {
        ...state.waypoints,
        ...arrayToObj(
          itineraryStore.itinerary.computed.currentWaypoints(),
          (waypoint) => waypoint.id,
        ),
      };

      const itineraryWaypoints = itinerary.waypoints
        .map(({ id }) => waypoints[id])
        .filter(nullishFilter);

      return itineraryWaypoints;
    },

  'itineraries/getWaypointByID': (state: S) =>
    (waypointID: string): Waypoint | null => state.waypoints?.[waypointID] ?? null,

  'itineraries/getItineraryByID': (state: S) =>
    (itineraryID?: string): Itinerary | null => state.itineraries?.[itineraryID ?? ''] ?? null,

  'itineraries/getWaypointActionByID': (state: S) =>
    (waypointActionID: string): WaypointAction | null => state.waypointActions?.[waypointActionID] ?? null,

  /** Supports both saved and draft itinerary */
  'itineraries/getWaypointActionsOnItinerary': (
    state: S,
    _getters: AnyGetters,
    _rootState: RootState,
  ) => (itineraryID: string): WaypointAction[] => {
    const waypoints = extraItinerariesGetters[
      'itineraries/getWaypointsOnItinerary'
    ](state, _getters)(itineraryID);

    const waypointActionIDs = flattenArray(waypoints.map((waypoint) =>
      waypoint.waypointActions?.map((idObj) => idObj.id) ?? [],
    ));
    const waypointActions = waypointActionIDs
      .map((id) => state.waypointActions?.[id])
      .filter(nullishFilter);

    return waypointActions;
  },
};

const itinerariesModuleExtension = {
  actions: extraItinerariesActions,
  getters: extraItinerariesGetters,
  mutations: extraItinerariesMutations,
};

export const itinerariesModule: ExtendedCustomModule<
  ItinerariesModule,
  typeof itinerariesModuleExtension
> = initItinerariesModule(itinerariesModuleExtension);

export type ExtendedItinerariesModule = typeof itinerariesModule;

export type ExtendedItinerariesMutations = ExtendedItinerariesModule['mutations'];
export type ExtendedItinerariesActions = ExtendedItinerariesModule['actions'];
export type ExtendedItinerariesGetters = ExtendedItinerariesModule['getters'];
